import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription, of, Observable } from 'rxjs';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-firmantes',
  templateUrl: './firmantes.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class FirmantesComponent implements OnInit, OnDestroy {
  @Input() eventEmitter: EventEmitter<Object>;
  message: string;
  firmantes: any[] = [];
  firmante: any[] = [];
  idc;
  escrituras: any[] = [];
  parentesco: any[] = [];
  firmantesfisicas: any[] = [];
  contribuyentes: any[];
  anfb = false;
  constructor(
               public _contribuyentesService: ContribuyentesService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void { 
    this.eventEmitter.unsubscribe();
    //console.log('DESTRUYO COMPONENTE FIRMANTES')

}

  request(idc): Observable<any[]> {
  let getEscrituras = this._mantenimientocontservice.getEscrituras(idc);
  let getParentezco = this._contribuyentesService.getParentezco();
  let getFrmantesFisicas = this._contribuyentesService.getFirmantes();
  let getContribuyentes = this._contribuyentesService.getContribuyentesMain()
  return forkJoin([getEscrituras, getParentezco, getFrmantesFisicas, getContribuyentes]);
}

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe(d => {
    this.anfb = false;
    this.firmante = [];
    this.firmantes = [];
    this.idc = d.idc;     
    this._mantenimientocontservice.getEscrituras( this.idc ).subscribe( resp => {this.escrituras = resp; } );
    this._contribuyentesService.getParentezco().subscribe( resp => this.parentesco = resp );
    this._contribuyentesService.getFirmantes().subscribe( resp => {this.firmantesfisicas = resp;});
    this._mantenimientocontservice.getFirmantes(this.idc).subscribe( resp => {this.firmantes = resp; //console.log(this.firmantes)
      // tslint:disable-next-line: forin
      /*for (const prop in this.escrituras) {
        for (const prep in this.firmantes) {
          if ( this.escrituras[prop].id == this.firmantes[prep].property_document_id ) {
            this.firmantes[prep].nambre = this.escrituras[prop].document_type;
          }
        }
      }
      // tslint:disable-next-line: forin
      for (const prop in this.contribuyentes) {
        for (const prep in this.firmantes) {
          if (this.contribuyentes[prop].id_contribuyente == this.firmantes[prep].contributor_signatory_id) {
            this.firmantes[prep].nambrecontribuyente = this.contribuyentes[prop].nombre;
          }

        }
      }
      for(const prop in this.firmantes) {
        for(const prep in this.firmantesfisicas) {
          if(this.firmantes[prop].contributor_signatory_id==this.firmantesfisicas[prep].id) {
            this.firmantes[prop].nambrecontribuyente = this.firmantesfisicas[prep].nombre;
            break;
          }
      }

}*/
} );
    })
}

anf() {
  this.anfb = !this.anfb;
}

borromodocreo() {
    this.firmante = [];
    this.firmantes = [];  
    this.anfb = false;  
    this._mantenimientocontservice.getEscrituras( this.idc ).subscribe( resp => {this.escrituras = resp; } );
    this._contribuyentesService.getParentezco().subscribe( resp => this.parentesco = resp );
    this._contribuyentesService.getFirmantes().subscribe( resp => {this.firmantesfisicas = resp;});
    this._mantenimientocontservice.getFirmantes(this.idc).subscribe( resp => {this.firmantes = resp;} );
}

agregaFirmante() {
  swal2.showLoading();
  const escritura: any = document.getElementById('escriturafirmantep');

  const valorescritura = escritura.options[escritura.selectedIndex].value;

  const parentesco: any = document.getElementById('fpartnershipp');

  const valorparentesco = parentesco.options[parentesco.selectedIndex].value;

  const contribuyente: any = document.getElementById('firmantep');

  const valorcontribuyente = contribuyente.options[contribuyente.selectedIndex].value;

  const params = {
  token: '',
  secret_key: '',
  contributor_signatory_id: valorcontribuyente,
  property_document_id: valorescritura,
  partnership: valorparentesco
};

  this._contribuyentesService.creaFirmantexContribuyente(this.idc, params).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        this.borromodocreo();
      }

    } );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    this.borromodocreo();
  }
} );
});
}

getFirmante() {
  const firmante: any = document.getElementById('firmantes');
  const valorfirmante = firmante.options[firmante.selectedIndex].value;
  if (valorfirmante !== 'novalor') {
    const person = this.firmantes.find(object => {
      return object.id == valorfirmante;
    });
    this.firmante[0] = person;
 /* for (const prop in this.firmantes) {
    if (this.firmantes[prop].id == valorfirmante) {
    this.firmante[0] = this.firmantes[prop];
    console.log(this.firmante)
    break;
    }
  } */
} else {
  this.firmante = [];
}
//console.log(this.firmante)
}

modificaFirmante(item) {
  swal2.showLoading();
  const params = {
    token: '',
    secret_key: '',
    contributor_signatory_id: item.contributor_signatory_id,
    property_document_id: item.property_document_id,
    partnership: item.partnership
  };

  if (item.property_document_id === null) {
    delete params.property_document_id;
  }

  console.log(item.contributor_id);
  console.log(item.id);
  console.log(params)
  this._mantenimientocontservice.actualizaFirmante( item.contributor_id, item.id, params ).subscribe( resp => {
    swal2.close();
    swal2.fire({
      title: 'Los datos se guardaron',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
        this.borromodocreo();
      }

    } );

}, (err) => {
swal2.close();
console.log(err);
swal2.fire({
  title: 'Ocurrio un error',
  text: '',
  icon: 'error',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  if ( res.value ) {
    window.location.reload();
  }
} );
});
}

borraFirmante(item) {
swal2.fire({
  title: 'Desea elminiar el firmante seleccionado',
  text: '?',
  icon: 'question',
  showConfirmButton: true,
  showCancelButton: true,
  allowOutsideClick: false
}). then ( resp => {
  if ( resp.value) {

    this._mantenimientocontservice.borraFirmante( item.contributor_id, item.id ).subscribe( () => {

      swal2.fire({
        title: 'El firmante se elimino',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.borromodocreo();
        }
      } );
}, (err) => {
  console.log(err);
  swal2.fire({
    title: 'Ocurrio un error',
    text: '',
    icon: 'error',
    showConfirmButton: true,
    showCancelButton: false,
    allowOutsideClick: false
  }). then ( res => {
    if ( res.value ) {
      this.borromodocreo();
    }
  } );
 });

  }
});
}



}