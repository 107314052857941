import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContribuyentesService } from '../../services/service.index';
import { Privilegio, Usuario2 } from '../../models/usuario.model';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Key } from 'readline';
import swal2 from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare function init_plugins();

@Component({
  selector: 'app-clientesscadena',
  templateUrl: './clientesscadena.component.html',
  styleUrls: ['./altacyp.component.css']
})
export class ClientesCadenaComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  forma: FormGroup;
  idl: string;
  cadena: any[] = [];
  idcadena = '';
  nombrenegocio = '';
  cols: any[];
  clientes: any[] = [];
  currency: any[] = [];
  displayDialognuevo: boolean;
  RFCFisica = false;
  correo = false;
  nombcont = '';
  nuevocliente = false;
  constructor(
    private route: ActivatedRoute,
    public _contribuyentesService: ContribuyentesService,
    public router: Router
  ) { }


  ngOnInit() {
    this.subscribeToParentEmitter();
    /*(document.getElementById('name') as HTMLInputElement).value = '';
    (document.getElementById('rfc') as HTMLInputElement).value = '';
    (document.getElementById('email') as HTMLInputElement).value = '';
    (document.getElementById('phone') as HTMLInputElement).value = '';
    (document.getElementById('address') as HTMLInputElement).value = ''; */
      
      this.cols = [
            { field: 'name', header: 'Nombre' },
            { field: 'rfc', header: 'RFC' },
            { field: 'status', header: 'Estatus' },
            { field: 'email', header: 'Correo' },
            { field: 'phone', header: 'Telefono' },
            { field: 'address', header: 'Direccion' },
            
        ];
  }

  subscribeToParentEmitter(): void { 
    
    this.eventEmitter.subscribe(d => {
    Swal.showLoading();
    this.idcadena = d.idcadena;
    this.nombcont = d.nomcont;
    this._contribuyentesService.getclientescadena(this.idcadena).subscribe( resp => {
      this.clientes = resp
    } )
    })
  }

  ncliente() {
    this.nuevocliente = true;
    (document.getElementById('name') as HTMLInputElement).value = '';
    (document.getElementById('rfc') as HTMLInputElement).value = '';
    (document.getElementById('email') as HTMLInputElement).value = '';
    (document.getElementById('phone') as HTMLInputElement).value = '';
    (document.getElementById('address') as HTMLInputElement).value = '';
    (document.getElementById('days') as HTMLInputElement).value = '';
    (document.getElementById('rate') as HTMLInputElement).value = '';
    (document.getElementById('expiration_day') as HTMLInputElement).value = '';
  }

  nclienteno() {
    this.nuevocliente = false;
    (document.getElementById('name') as HTMLInputElement).value = '';
    (document.getElementById('rfc') as HTMLInputElement).value = '';
    (document.getElementById('email') as HTMLInputElement).value = '';
    (document.getElementById('phone') as HTMLInputElement).value = '';
    (document.getElementById('address') as HTMLInputElement).value = '';
    (document.getElementById('days') as HTMLInputElement).value = '';
    (document.getElementById('rate') as HTMLInputElement).value = '';
    (document.getElementById('expiration_day') as HTMLInputElement).value = '';
  }

  regresodemodborroagre() {
    this._contribuyentesService.getclientescadena(this.idcadena).subscribe( resp => {
      this.clientes = resp
    } );
    this.nclienteno();
  }

creaClienteCadena() {
  const data = {
    name: (document.getElementById('name') as HTMLInputElement).value,
    rfc: (document.getElementById('rfc') as HTMLInputElement).value,
    status: (document.getElementById('status') as HTMLInputElement).value,
    email: (document.getElementById('email') as HTMLInputElement).value,
    phone: (document.getElementById('phone') as HTMLInputElement).value,
    address: (document.getElementById('address') as HTMLInputElement).value,
    days: (document.getElementById('days') as HTMLInputElement).value,
    rate: (document.getElementById('rate') as HTMLInputElement).value,
    expiration_day: (document.getElementById('expiration_day') as HTMLInputElement).value,
    token: '',
    secret_key: ''
  }
  if (data.name.length > 0 && data.rfc.length > 0 ) {
  this._contribuyentesService.creaClienteCadena( this.idcadena, data ).subscribe( resp => {
    swal2.fire({
      title: 'Los datos fueron guardados',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
       // window.location.reload();
       this.regresodemodborroagre();
      }

    } );
  }, (err) => {
    console.log(err);
    swal2.fire(
         'Error al guardar los datos',
         '',
         'error'
      );
   } );
  } else {
    swal2.fire(
      'Los datos marcados con * son requeridos'
   );
  }
}  

modCliente(item) {
  const data = {
    name: item.name,
    rfc: item.rfc,
    status: item.status,
    email: item.email,
    phone: item.phone,
    address: item.address,
    days: item.days,
    rate: item.rate,
    expiration_day: item.expiration_day,
    token: '',
    secret_key: ''
  }
  this._contribuyentesService.actualizaCliente(item.company_id, item.id , data).subscribe( resp => {
    swal2.fire({
      title: 'Los datos fueron actualizados',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
       // window.location.reload();
       this.regresodemodborroagre();
      }

    });
  }, (err) => {
    console.log(err);
    swal2.fire(
         'Error al actualizar los datos',
         '',
         'error'
      );
   } );
}

del(seg) {
  swal2.fire({
    title: 'Desea Eliminar el cliente',
    text: '',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }). then ( resp => {
    if ( resp.value) {

  this._contribuyentesService.borraCliente(seg.company_id, seg.id).subscribe(resp => {

    swal2.fire({
      title: 'Los datos fueron borrados',
      text: 'Con exito',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {

      if ( res.value ) {
       // window.location.reload();
       this.regresodemodborroagre();
      }

    } );
  }, (err) => {
    console.log(err);
    swal2.fire(
         'Error al borrar los datos',
         err.error.errors[0],
         'error'
      );
   } );

}
});
}

validaRFC() {

    
    const RFC = (document.getElementById('rfc') as HTMLInputElement).value;
    if (RFC.length === 13) {
    const regex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}/;
    const resultado = regex.test(RFC);
    if ( resultado === false ) {
      document.getElementById('rfc').focus();
      this.RFCFisica = true;
    } else {
      this.RFCFisica = false;
    }
    } 
    else if (RFC.length === 12) {
      const regex = /^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}/;
      const resultado = regex.test(RFC);
      if ( resultado === false ) {
        document.getElementById('rfc').focus();
        this.RFCFisica = true;
      } else {
        this.RFCFisica = false;
      }
    } 
    else {
      document.getElementById('rfc').focus();
      this.RFCFisica = true;
    }

}

validaEmail() {
  const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const correoFisica = (document.getElementById('email') as HTMLInputElement).value;
  const resultado = regex.test(correoFisica);

  if ( correoFisica.length > 0 ) {
  if ( resultado === false ) {
    document.getElementById('email').focus();
    this.correo = true;
  } else {
    this.correo = false;
  }
  } else {
    this.correo = false;
  }

  }

}
