import { Component, Input, OnInit } from '@angular/core';
import { StpService } from '../../../services/stp/stp.service';
import { FundersService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import { Router } from '@angular/router';
import { ParametrosGeneralesService } from '../../../services/parametrosgenerales/parametrosgenerales.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-traspasos-stp',
  templateUrl: './traspasos-stp.component.html',
  styles: []
})
export class TraspasosStpComponent implements OnInit {

  constructor(private _stpservice: StpService,
              private _fundersservice: FundersService,
              private parametrosService: ParametrosGeneralesService) { }

  saldo = 0;
  token = localStorage.getItem('token');

  state;
  newState;
  preState;
  checkState = false;
  facturas: any[] = [];
  funders_banks: any[] = [];
  funders_data: any[] = [];
  suppliers_banks: any[] = [];
  suppliers_data: any[] = [];
  usuario: string;
  essupplier: boolean;
  cols: any[];
  colspdf: any[];

  selectedFac: any[];
  router: Router;
  selectedColumnsp: any[];
  selectedColumnspdf: any[];
  exportColumns: any[];
  selectedInvoices = [];

  monto: any;
  banco:any;
  concepto:any;
  nombre_beneficiario:any;
  cuenta_destino:any;
  institucion:any;
  cuenta_final:any;
  beneficiario:any[];
  referencia:any;
  transferencia:any;

  nombre_ordenante = '';
  tipo_cuenta_ordenante = '';
  cuenta_ordenante = '';
  forma:FormGroup;

  _selectedColumns: any[];

  ngOnInit() {

      this.forma = new FormGroup({
        institucion_origen: new FormControl( null , Validators.required ),
        cuenta_origen: new FormControl( null , Validators.required ),
        monto: new FormControl( null , Validators.required ),
        concepto: new FormControl( null , Validators.required ),
        institucion: new FormControl( null , Validators.required ),
        cuenta_destino: new FormControl( null , Validators.required ),
        referencia: new FormControl( null , Validators.required ),
        funders_data: new FormControl( null , Validators.required ),
        suppliers_data: new FormControl( null , Validators.required )
      });

    this.cols = [
      
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}
      
    ];
    
    this._selectedColumns = this.cols;
    this.colspdf = [
      { field:  'institucion_contraparte', header: 'Institucion Contraparte'},
      { field:  'clave_rastreo', header: 'Clave de Rastreo'},
      { field:  'nombre_beneficiario', header: 'Nombre de Beneficiario'},
      { field:  'rfc_curp_beneficiario', header: 'Rfc Curp Beneficiario'},
      { field:  'tipo_pago', header: 'Tipo de Pago'},
      { field:  'tipo_cuenta_beneficiario', header: 'Tipo Cuenta Beneficiario'},
      { field:  'monto', header: 'Monto'},
      { field:  'cuenta_beneficiario', header: 'Cuenta Beneficiario'},
      { field:  'concepto_pago', header: 'Concepto de Pago'},
      { field:  'referencia_numerica', header: 'Referencia Numerica'},
      { field:  'institucion_operante', header: 'Institucion Operante'},
      { field:  'empresa', header: 'Empresa'}
    ];
    this.selectedColumnsp = this.cols;
    this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));
    this.forma.get('institucion_origen').disable();
    this.forma.controls['institucion_origen'].disable();
    this.get_funder_supplier_info('suppliers');
    this.parametrosService.getGeneralParam('NOMBRE_ORDENANTE').subscribe(resp => {
      this.nombre_ordenante = resp['value'];

      this.parametrosService.getGeneralParam('CUENTA_ORIGEN_STP').subscribe(resp => {
        this.cuenta_ordenante = resp['value'];

        this.parametrosService.getGeneralParam('TIPO_CUENTA_ORDENANTE').subscribe(resp => {
          this.tipo_cuenta_ordenante = resp['value'];

          this.consulta_saldo_stp();
        })
      })
    })
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  consulta_saldo_stp(){
    const params = {
      empresa: this.nombre_ordenante,
      cuentaOrdenante: this.cuenta_ordenante
    };
    this._stpservice.getStpAccountBalanceStp(params)
    .subscribe(resp => {
      try{
        this.saldo = resp['response']['respuesta']['saldo'];
        swal2.close();
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de saldo', 'info');
        return
      }
      
    } , err => {
      console.log(err)
      swal2.fire({title: 'Ocurrio un error al cargar la informacion', allowOutsideClick: false })} );
  }

  get_funder_supplier_info(value){
    if(value == 'funder'){
      this.trae_datos_bancarios_funders();
      this.essupplier = false;
      this.cuenta_final = '';
      this.institucion = '';
    }else{
      this.trae_datos_bancarios_suppliers();
      this.essupplier = true;
      this.cuenta_final = '';
      this.institucion = '';
    }
  }

  confirmarDispersionTraspaso(){
    let ordenPago = {};
    let correcto = [];
    let error = [];
    this.monto = (document.getElementById('monto')as HTMLInputElement).value;
    this.concepto = (document.getElementById('concepto')as HTMLInputElement).value;
    this.referencia = (document.getElementById('referencia')as HTMLInputElement).value;
    ordenPago['counterpart_institution'] = this.institucion,
    ordenPago['business'] = 'FLIEBEN3',
    ordenPago['tracking_code'] = '',
    ordenPago['operating_institution'] = 90646,
    ordenPago['amount'] = this.monto,
    ordenPago['payment_type'] = 1,
    ordenPago['ordering_account_type'] = this.tipo_cuenta_ordenante,
    ordenPago['ordering_name'] = this.nombre_ordenante,
    ordenPago['ordering_account'] = this.cuenta_ordenante,
    ordenPago['ordering_rfc_curp'] = 'ND',
    ordenPago['beneficiary_account_type'] = 3,
    ordenPago['beneficiary_name'] = this.nombre_beneficiario,
    ordenPago['beneficiary_account'] = this.cuenta_final,
    ordenPago['beneficiary_rfc_curp'] = 'ND',
    ordenPago['payment_concept'] = this.concepto.substring(0,140),
    ordenPago['numeric_reference'] = this.referencia.substring(0,7),
    ordenPago['iva'] = ''
    swal2.fire({
    title: 'Estas a punto de hacer un deposito, ¿Estas seguro?',
    html: `Por favor, verifique que los datos sean correctos: <br>
            <div class="row">
              <div class="col">
                <br><b>Nombre del Beneficiario:</b> ${this.nombre_beneficiario} <br>
                <b>Banco del Beneficiario:</b> ${this.institucion} <br>
                <b>Cuenta del Beneficiario:</b> ${this.cuenta_final} <br>
                <b>Monto:</b> ${this.monto} <br>
                <b>Concepto:</b> ${this.concepto} <br>
                <b>Referencia:</b> ${this.referencia} <br>
              </div>
            </div>`,
    icon: 'warning',
    onOpen: function () {
      setTimeout(function () {
        $(".swal2-confirm").show();
      }, 5000);
    },
    showCancelButton: true,
    showConfirmButton: false,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar traspaso',
    cancelButtonText: 'Cancelar'
  }).then(() => {
    let params = {};
    params['ordenPago'] = ordenPago;
    this._stpservice.registraOrdenPago(params).subscribe(resp => {
      console.log('correcto', params, resp);
      correcto.push(params);
      swal2.fire('Transferencia1 enviada', 'Finalizó la dispersión, correctos: ' + correcto.length + ', errores: ' + error.length, 'success');
      // location.reload();
    }, err => {
      console.log('error', params, err);
      error.push(params);
      swal2.fire('Ocurrio un error', 'La dispersión no se realizó: ' + correcto.length + ', errores: ' + error.length, 'error');
      // location.reload();
    });
  })
  }

  // TO DO: CORREGIR EL BOTON DE CANCELAR DEL MODAL DE ARRIBA

  // TO DO: OBTENER CAMPOS DINAMICAMENTE: beneficiary_account_type, ordering_account_type, payment_type,operating_institution

  setValue(){
    let valor =  (document.getElementById('concepto')as HTMLInputElement).value;
    this.concepto = valor;
  }

  setReferenciaValue(){
    let valor_referencia =  (document.getElementById('referencia')as HTMLInputElement).value;
    this.referencia = valor_referencia;
  }

  selectedOption(option) {
    return this.newState === option;
  }

  trae_datos_bancarios_funders(){
    this._fundersservice.getFundersBankData()
    .subscribe(resp => {
      try{
        this.funders_data = resp['funders'];
        this.funders_banks = resp['funder_contributor_data'];
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de datos bancarios de los fondeadores', 'info');
        return
      }
    })
  }

  trae_datos_bancarios_suppliers(){
    this._fundersservice.getSuppliersBankData()
    .subscribe(resp => {
      try{
        this.suppliers_data = resp['suppliers'];
        this.suppliers_banks = resp['suppliers_contributor_data'];
      }catch{
        swal2.fire('Atención', 'Hubo un problema en la consulta de datos bancarios de los proveedores', 'info');
        return
      }
    })
  }

  set_account_supplier(proveedor){
    this.cuenta_final = '';
    this.institucion = '';
    this.nombre_beneficiario = proveedor.options[proveedor.selectedIndex].text
    this.cuenta_destino = this.suppliers_banks.find( cuenta => cuenta.id == parseInt(proveedor.options[proveedor.selectedIndex].value));
    this.cuenta_final = this.cuenta_destino['account_number']
    this.institucion = this.cuenta_destino['bank']
  }

  set_account_funder(fondeador){
    this.cuenta_final = '';
    this.institucion = '';  
    this.nombre_beneficiario = fondeador.options[fondeador.selectedIndex].text
    this.cuenta_destino = this.funders_banks.find(cuenta => cuenta.id == parseInt(fondeador.options[fondeador.selectedIndex].value));
    this.cuenta_final = this.cuenta_destino['account_number']
    this.institucion = this.cuenta_destino['bank']
  }

  // dispersar(){
  //   console.log('selected', this.selectedInvoices);
  //   let ordenPago = {};
  //   let correcto = [];
  //   let error = [];
  //   this.selectedInvoices.forEach(i => {
  //     ordenPago['counterpart_institution'] = i.institucion_contraparte,
  //     ordenPago['business'] = i.empresa,
  //     ordenPago['tracking_code'] = '',
  //     ordenPago['operating_institution'] = i.institucion_operante,
  //     ordenPago['amount'] = i.monto,
  //     ordenPago['payment_type'] = i.tipo_pago,
  //     ordenPago['ordering_account_type'] = this.tipo_cuenta_ordenante,
  //     ordenPago['ordering_name'] = this.nombre_ordenante,
  //     ordenPago['ordering_account'] = this.cuenta_ordenante,
  //     ordenPago['ordering_rfc_curp'] = 'ND',
  //     ordenPago['beneficiary_account_type'] = i.tipo_cuenta_beneficiario,
  //     ordenPago['beneficiary_name'] = i.nombre_beneficiario,
  //     ordenPago['beneficiary_account'] = i.cuenta_beneficiario,
  //     ordenPago['beneficiary_rfc_curp'] = i.rfc_curp_beneficiario,
  //     ordenPago['payment_concept'] = i.concepto_pago,
  //     ordenPago['numeric_reference'] = i.referencia_numerica,
  //     ordenPago['iva'] = ''
  //   });
  //   let params = {};
  //   params['ordenPago'] = ordenPago;
  //   this._stpservice.registraOrdenPago(params).subscribe(resp => {
  //     console.log('correcto', params, resp);
  //     correcto.push(params);
  //   }, err => {
  //     console.log('error', params, err);
  //     error.push(params);
  //   });
  //   // TO DO: DESCOMENTAR ANTES DE SUBIR CAMBIOS
  //   // this.consulta_saldo_stp();
  //   console.log('resultado', correcto, error);

  //   swal2.fire('Terminado', 'Finalizó la dispersión, correctos: ' + correcto.length + ', errores: ' + error.length, 'success');
  // }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    this.setReferenciaValue();
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  formatCurrency() {
    // Remueve caracteres no numericos del value de un input
    let input = (document.getElementById('monto')as HTMLInputElement);
    let value = input.value.replace(/[^\d]+/g, '');
    const wholePart = value.slice(0, -2);
    const decimalPart = value.slice(-2);
    // Formatea la parte entera con comas cada 3 digitos
    let formattedValue = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Agrega el punto decimal en los ultimos 3 digitos
    formattedValue += `.${decimalPart}`; 
    // Actualiza el value del input con el valor formateado y le agrega el simbolo $
    input.value = "$"+formattedValue;
    this.monto = formattedValue;
    // Cambia el color del input basado en su valor 
    if (input.value === "") {
        input.style.color = "#757575";
    } else {
        input.style.color = "#14990d";
        input.style.fontWeight = "bold";
    }
  }

}
