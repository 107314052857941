import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ContribuyentesService, OptionsService, MantenimientoContribuyentesService, UsuarioService } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-actac',
  templateUrl: './actac.component.html',
  styleUrls: ['../mantcontmain.component.css']
})
export class ActaCComponent implements OnInit, OnDestroy {
  @Input() eventEmitter: EventEmitter<Object>;
  message: string;
  idc;
  actas: any = [];
  contribuyentes: any[];
  representantesLegales: any[];
  anfb = false;
  nofir = false;
  regUsuarioActaNueva = {
    company_contributor_id: '',
    category: '',
    representative_contributor_id: '',
    public_deed: '',
    book: '',
    deed_date: '',
    deed_state: '',
    deed_volume: '',
    holder_licensee: '',
    inscription_number: 'N/A',
    judicial_district: '',
    notary_number: '',
    place: '',
    state_signed_up: '',
    testimony_date: '',
    trade_folio: '',
  };
  regUsuarioActa = {
    id: '',
    company_contributor_id: '',
    category: '',
    representative_contributor_id: '',
    public_deed: '',
    book: '',
    deed_date: '',
    deed_state: '',
    deed_volume: '',
    holder_licensee: '',
    inscription_number: 'N/A',
    judicial_district: '',
    notary_number: '',
    place: '',
    state_signed_up: '',
    testimony_date: '',
    trade_folio: '',
  };
  constructor(
               public _contribuyentesService: ContribuyentesService,
               private usuarioService: UsuarioService,
               public _optionsservice: OptionsService,
               public _mantenimientocontservice: MantenimientoContribuyentesService,
               public router: Router,
               public http: HttpClient ) { }

  ngOnInit() {
    this.subscribeToParentEmitter();
  }

  ngOnDestroy(): void { 
    this.eventEmitter.unsubscribe();
    //console.log('DESTRUYO COMPONENTE ACTAC')

}



  subscribeToParentEmitter(): void { 
    this.regUsuarioActaNueva = {
      company_contributor_id: '',
      category: '',
      representative_contributor_id: '',
      public_deed: '',
      book: '',
      deed_date: '',
      deed_state: '',
      deed_volume: '',
      holder_licensee: '',
      inscription_number: 'N/A',
      judicial_district: '',
      notary_number: '',
      place: '',
      state_signed_up: '',
      testimony_date: '',
      trade_folio: '',
    };
    this.eventEmitter.subscribe(d => {
    swal2.fire('Cargando informacion de actas', 'por favor espere', 'info');
    swal2.showLoading();
    this.actas = [];
    this.idc = d.idc;
    this.anfb = false;
    this.nofir = false;
    this.regUsuarioActaNueva.company_contributor_id = this.idc;
    // declaramos los observables necesarios para el inicio
    const observable1 = this._contribuyentesService.getContribuyentesMain();
    const observable2 = this._mantenimientocontservice.getFirmantes(this.idc);
    const observable3 = this.usuarioService.getPublicDeedsByIdc(this.idc).pipe(catchError(error => { return of (error); }));
    forkJoin([observable1, observable2, observable3]).subscribe(async ([contribuyentes, firmantes, actas]) => {
      // asignamos los resultados a los objetos
      this.contribuyentes = contribuyentes;
      this.representantesLegales = firmantes;
      //checamos que no hubo error en las actas (en este caso hay un "error" que es "bueno" el cual es que no se encuentren actas)
      if (!actas.error && actas.data.length > 0) {
        this.actas = [];
        //esperar al filtrado de actas ya que solo competen las que tengan la empresa
        this.actas = await this.filtractas(actas);
        for (const prop in this.actas) {
          let cname = this.contribuyentes.find(s => s.id_contribuyente === this.actas[prop].company_contributor_id);
          let lrname = this.contribuyentes.find(s => s.id_contribuyente === this.actas[prop].representative_contributor_id);
          this.actas[prop].cnombre = cname.nombre;
          this.actas[prop].rlnombre = lrname.nombre;
        } 
      }
      
      if (this.representantesLegales.length > 0) {
        for (const prop in this.representantesLegales) {
          let cname = this.contribuyentes.find(s => s.id_contribuyente === this.representantesLegales[prop].contributor_signatory_id);
          this.representantesLegales[prop].nombreRl = cname.nombre;
        }
        swal2.close();
      } else {
        swal2.close();
        this.nofir = true;
        swal2.fire({
          title: 'Atención', 
          text: 'No se encontraron firmantes dados de alta', 
          icon: 'info',
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(res => {
          if(res.value){
            // this.ngOnInit();
            swal2.close();
          }
        });
      }
      
    }, (err) => {
      swal2.fire({
        title: 'Atención', 
        text: 'Algo salió mal, contacte al administrador', 
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          // this.ngOnInit();
          swal2.close();
        }
      });
    }); 
    })
}

async filtractas(actas: { [x: string]: any[]; }) {
  /* se hace una promesa para resolver cuando se tengan datos en el arreglo*/
  return new Promise(resolve => {
    let actasres = [];
    setTimeout(() => {
      try {
        actas['data'].forEach(d => {
          if (d.company_contributor_id === this.idc) {
            actasres.push(d);
          }
        });
        resolve(actasres);
      } catch (error) {
        resolve(error)
      }
      
    }, 300);
  });
}

borroactualizoocreo() {
    this.regUsuarioActaNueva.company_contributor_id = '';
    this.regUsuarioActaNueva.category= '';
    this.regUsuarioActaNueva.representative_contributor_id= '';
    this.regUsuarioActaNueva.public_deed= '';
    this.regUsuarioActaNueva.book= '';
    this.regUsuarioActaNueva.deed_date= '';
    this.regUsuarioActaNueva.deed_state= '';
    this.regUsuarioActaNueva.deed_volume= '';
    this.regUsuarioActaNueva.holder_licensee= '';
    this.regUsuarioActaNueva.inscription_number= 'N/A';
    this.regUsuarioActaNueva.judicial_district= '';
    this.regUsuarioActaNueva.notary_number= '';
    this.regUsuarioActaNueva.place= '';
    this.regUsuarioActaNueva.state_signed_up= '';
    this.regUsuarioActaNueva.testimony_date= '';
    this.regUsuarioActaNueva.trade_folio= '';
  
    swal2.fire('Cargando informacion de actas', 'por favor espere', 'info');
    swal2.showLoading();
    this.actas = [];
    this.anfb = false;
    this.nofir = false;
    this.regUsuarioActaNueva.company_contributor_id = this.idc;
    // declaramos los observables necesarios para el inicio
    const observable1 = this._contribuyentesService.getContribuyentesMain();
    const observable2 = this._mantenimientocontservice.getFirmantes(this.idc);
    const observable3 = this.usuarioService.getPublicDeedsByIdc(this.idc).pipe(catchError(error => { return of (error); }));
    forkJoin([observable1, observable2, observable3]).subscribe(async ([contribuyentes, firmantes, actas]) => {
      // asignamos los resultados a los objetos
      this.contribuyentes = contribuyentes;
      this.representantesLegales = firmantes;
      //checamos que no hubo error en las actas (en este caso hay un "error" que es "bueno" el cual es que no se encuentren actas)
      if (!actas.error && actas.data.length > 0) {
        this.actas = [];
        //esperar al filtrado de actas ya que solo competen las que tengan la empresa
        this.actas = await this.filtractas(actas);
        for (const prop in this.actas) {
          let cname = this.contribuyentes.find(s => s.id_contribuyente === this.actas[prop].company_contributor_id);
          let lrname = this.contribuyentes.find(s => s.id_contribuyente === this.actas[prop].representative_contributor_id);
          this.actas[prop].cnombre = cname.nombre;
          this.actas[prop].rlnombre = lrname.nombre;
        } 
      }
      
      if (this.representantesLegales.length > 0) {
        for (const prop in this.representantesLegales) {
          let cname = this.contribuyentes.find(s => s.id_contribuyente === this.representantesLegales[prop].contributor_signatory_id);
          this.representantesLegales[prop].nombreRl = cname.nombre;
        }
        swal2.close();
      } else {
        swal2.close();
        this.nofir = true;
        swal2.fire({
          title: 'Atención', 
          text: 'No se encontraron firmantes dados de alta', 
          icon: 'info',
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(res => {
          if(res.value){
            // this.ngOnInit();
            swal2.close();
          }
        });
      }
      
    }, (err) => {
      swal2.fire({
        title: 'Atención', 
        text: 'Algo salió mal, contacte al administrador', 
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          // this.ngOnInit();
          swal2.close();
        }
      });
    }); 
}

anf() {
  this.anfb = !this.anfb;
}

datosNuevaActa() {
  this._mantenimientocontservice.getFirmantes(this.idc).subscribe( resp => {this.representantesLegales = resp;
    //console.log(this.contribuyentes)
    if (this.representantesLegales.length > 0) {
      for (const prop in this.representantesLegales) {
        let cname = this.contribuyentes.find(s => s.id_contribuyente === this.representantesLegales[prop].contributor_signatory_id);
        this.representantesLegales[prop].nombreRl = cname.nombre;
      }
    } 
    //console.log(this.representantesLegales)
  } );
}

agregaNuevaActa() {
  
  if (Object.values(this.regUsuarioActaNueva).includes("")) {
    swal2.fire('Todos los campos son requeridos','','info');
    return;
  }
  let params = {};
  params['public_deeds'] = this.regUsuarioActaNueva;
  //console.log(params)
  this.usuarioService.createPublicDeed(params).subscribe(resp => {
    swal2.fire({
      title: 'Acta creada correctamente',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then (resp => {
      this.borroactualizoocreo();
    })  
  }, err => {
    console.log(err);
    swal2.fire('Atención', err.error.error, 'info');
  })
  //console.log(this.regUsuarioActaNueva);

}

editar(acta) {
  //console.log(acta);
  this.regUsuarioActa.id = acta.id,
  this.regUsuarioActa.company_contributor_id = acta.company_contributor_id,
  this.regUsuarioActa.category = acta.category,
  this.regUsuarioActa.representative_contributor_id = acta.representative_contributor_id,
  this.regUsuarioActa.public_deed = acta.public_deed,
  this.regUsuarioActa.book = acta.book,
  this.regUsuarioActa.deed_date = acta.deed_date,
  this.regUsuarioActa.deed_state = acta.deed_state,
  this.regUsuarioActa.deed_volume = acta.deed_volume,
  this.regUsuarioActa.holder_licensee = acta.holder_licensee,
  this.regUsuarioActa.inscription_number = 'N/A',
  this.regUsuarioActa.judicial_district = acta.judicial_district,
  this.regUsuarioActa.notary_number = acta.notary_number,
  this.regUsuarioActa.place = acta.place,
  this.regUsuarioActa.state_signed_up = acta.state_signed_up,
  this.regUsuarioActa.testimony_date = acta.testimony_date,
  this.regUsuarioActa.trade_folio = acta.trade_folio
}

modificaActaC(acta) {
  this.usuarioService.patchPublicDeed(acta.id, {public_deeds: acta}).subscribe(resp => {
    swal2.fire({
      title: 'Acta actualizada correctamente',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then (resp => {
      this.borroactualizoocreo();
    })
  }, err => {
    console.log(err);
      swal2.fire('Atención', err.error.error, 'info');
  })
}

eliminarActa(acta){
  swal2.fire({
    title: 'Desea elmiiniar el acta',
    text: '?',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }). then ( resp => {
    if ( resp.value) {
      this.usuarioService.deletePublicDeed(acta.id).subscribe(resp => {
      swal2.fire({
        title: 'Acta eliminada correctamente',
        text: '',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then (resp => {
        this.borroactualizoocreo();
      })  
      }, err => {
         console.log(err);
         swal2.fire('Atención', 'Ocurrio un error al eliminar el acta', 'info');
     }) 
    }
  });
}

}