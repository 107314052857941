import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContribuyentesService } from '../../services/service.index';
import { Privilegio, Usuario2 } from '../../models/usuario.model';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Key } from 'readline';

declare function init_plugins();

@Component({
  selector: 'app-actualizacadena',
  templateUrl: './actualizacadena.component.html',
  styleUrls: ['./altacyp.component.css']
})
export class ActualizaCadenaComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  clientesEmitter = new EventEmitter<Object>();
  forma: FormGroup;
  idl: string;
  cadena: any[] = [];
  idcadena = '';
  nombrenegocio = '';
  comanysector: any[] = [];
  comanysubsector: any[] = [];
  comanydigitalsign: any[] = [];
  nombcont = '';
  segcad = false;
  segmentos: any[] = [];
  currency: any[] = [];
  segpesos = false;
  segdolares = false;
  segmentopesos: any[] = [];
  segmentodolares: any[] = [];
  constructor(
    private route: ActivatedRoute,
    public _contribuyentesService: ContribuyentesService,
    public router: Router
  ) { }


  ngOnInit() {
      this.subscribeToParentEmitter();

      this.forma = new FormGroup({
        Key: new FormControl( null , Validators.required )
      } );
  }

  subscribeToParentEmitter(): void { 
    
    this.eventEmitter.subscribe(d => {
    Swal.showLoading();
    //const checkele = document.getElementById("one") as HTMLInputElement;
    //checkele.checked = true;
    this.idl = d.contid;
    this.nombcont = d.nomcont;
    this._contribuyentesService.getCadenaxcontribuyente( this.idl ).subscribe( resp => { this.cadena = resp; this.idcadena = this.cadena[0].id; this.nombrenegocio = this.cadena[0].business_name;
        this.clientesEmitter.emit({idcadena: this.idcadena, nomcont: this.nombrenegocio});
        this._contribuyentesService.getCompanySegmentsCurrency().subscribe( resp => this.currency = resp );
        this._contribuyentesService.getCompanySegments(this.idcadena).subscribe( resp => {this.segmentos = resp;
            for (const prop in this.segmentos) {
                if (this.segmentos[prop].with_recourse === true) {
                  this.segmentos[prop].with_recourse = 'SI'
                }
                if (this.segmentos[prop].with_recourse === false) {
                  this.segmentos[prop].with_recourse = 'NO'
                }
            }
            if (this.segmentos.length > 0) {
              this.segmentos.forEach((element => {
                if (element.currency == 'PESOS') {
                  this.segpesos = true;
                  this.segmentopesos[0] = element;
                }
                if (element.currency == 'DOLARES') {
                  this.segdolares = true;
                  this.segmentodolares[0] = element;
                }
                }))
            } else {
              this.segpesos = false;
              this.segdolares = false;
            }
        } );
      });
      this._contribuyentesService.getCompanySector().subscribe( resp => { this.comanysector = resp; } );
      this._contribuyentesService.getCompanyDigitalSign().subscribe( resp => { this.comanydigitalsign = resp; Swal.close();} );
    })
  }

  actualizaBorraoGuarda(idl) {
    this._contribuyentesService.getCadenaxcontribuyente( idl ).subscribe( resp => { this.cadena = resp; this.idcadena = this.cadena[0].id; this.nombrenegocio = this.cadena[0].business_name;
      this.clientesEmitter.emit({idcadena: this.idcadena, nomcont: this.nombrenegocio});
      this._contribuyentesService.getCompanySegmentsCurrency().subscribe( resp => this.currency = resp );
      this._contribuyentesService.getCompanySegments(this.idcadena).subscribe( resp => {this.segmentos = resp;
          for (const prop in this.segmentos) {
              if (this.segmentos[prop].with_recourse === true) {
                this.segmentos[prop].with_recourse = 'SI'
              }
              if (this.segmentos[prop].with_recourse === false) {
                this.segmentos[prop].with_recourse = 'NO'
              }
          }
          if (this.segmentos.length > 0) {
            this.segmentos.forEach((element => {
              if (element.currency == 'PESOS') {
                this.segpesos = true;
                this.segmentopesos[0] = element;
              }
              if (element.currency == 'DOLARES') {
                this.segdolares = true;
                this.segmentodolares[0] = element;
              }
              }))
          } else {
            this.segpesos = false;
            this.segdolares = false;
          }
          
      } );
    });
    this._contribuyentesService.getCompanySector().subscribe( resp => { this.comanysector = resp; } );
    this._contribuyentesService.getCompanyDigitalSign().subscribe( resp => { this.comanydigitalsign = resp; Swal.close();} );
  }

  actualizaCadena() {

    const params = {
    token: '',
    secret_key: '',
    business_name: (document.getElementById('business_name') as HTMLInputElement).value,
    sector: (document.getElementById('sector') as HTMLInputElement).value,
    subsector:  (document.getElementById('subsector') as HTMLInputElement).value,
    document:    (document.getElementById('document') as HTMLInputElement).value,
    credit_available:       (document.getElementById('credit_available') as HTMLInputElement).value,
    balance:   (document.getElementById('balance') as HTMLInputElement).value,
    digital_sign: (document.getElementById('digital_sign') as HTMLInputElement).value,
  };
    this._contribuyentesService.actualizaCadenaxContributente( this.idl, this.cadena[0].id , params).subscribe( () => {this.router.navigate(['/altacyp']),
    Swal.fire(
      'Modificacion de Cadena',
      'Exitosa',
      'success'
   ); }, (err) => {
                            Swal.fire(
                              'Error al modificar Cadena',
                              'Error',
                              'error'
                           );
                        } );

  }

  //PARA LOS SEGMENTOS
  creaSegmentoCadenaPesos() {

    const d = new Date((document.getElementById('start_datep')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    const startdaten = [year, month, day].join('-');
    const e = new Date((document.getElementById('end_datep')as HTMLInputElement).value);
    e.setMinutes( e.getMinutes() + e.getTimezoneOffset() );
    let monthe = '' + (e.getMonth() + 1);
    let daye = '' + e.getDate();
    const yeare = e.getFullYear();
    if (monthe.length < 2) {
        monthe = '0' + monthe;
    }
    if (daye.length < 2) {
        daye = '0' + daye;
    }
    const enddaten = [yeare, monthe, daye].join('-');
       const data = {company_id: this.idcadena, id: '',
       key: (document.getElementById('keyp') as HTMLInputElement).value,
       name: (document.getElementById('namep') as HTMLInputElement).value,
       start_date: startdaten,
       end_date: enddaten,
       rate: (document.getElementById('ratep') as HTMLInputElement).value,
       fee: (document.getElementById('feep') as HTMLInputElement).value,
       capacity: (document.getElementById('capacityp') as HTMLInputElement).value,
       limit_days: (document.getElementById('limit_daysp') as HTMLInputElement).value,
       currency: (document.getElementById('currencyp') as HTMLInputElement).value,
       expiration_day: (document.getElementById('expiration_dayp') as HTMLInputElement).value,
       expiration_type: (document.getElementById('expiration_typep') as HTMLInputElement).value,
       status: (document.getElementById('statusp') as HTMLInputElement).value,
       with_recourse: (document.querySelector('input[name = "with_recoursep"]:checked') as HTMLInputElement).value,
       token: '',
       secret_key: ''};
    this._contribuyentesService.guardaSegmento( this.idcadena, data ).subscribe( resp => {
      Swal.fire({
        title: 'Los datos fueron guardados',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
         // window.location.reload();
         this.actualizaBorraoGuarda(this.idl);
        }

      } );
    }, (err) => {
      console.log(err);
      Swal.fire(
           'Error al guardar los datos',
           '',
           'error'
        );
     } );

  }

  creaSegmentoCadenaDolares() {
    const d = new Date((document.getElementById('start_dated')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    const startdaten = [year, month, day].join('-');
    const e = new Date((document.getElementById('end_dated')as HTMLInputElement).value);
    e.setMinutes( e.getMinutes() + e.getTimezoneOffset() );
    let monthe = '' + (e.getMonth() + 1);
    let daye = '' + e.getDate();
    const yeare = e.getFullYear();
    if (monthe.length < 2) {
        monthe = '0' + monthe;
    }
    if (daye.length < 2) {
        daye = '0' + daye;
    }
    const enddaten = [yeare, monthe, daye].join('-');
       const data = {company_id: this.idcadena, id: '',
       key: (document.getElementById('keyd') as HTMLInputElement).value,
       name: (document.getElementById('named') as HTMLInputElement).value,
       start_date: startdaten,
       end_date: enddaten,
       rate: (document.getElementById('rated') as HTMLInputElement).value,
       fee: (document.getElementById('feed') as HTMLInputElement).value,
       capacity: (document.getElementById('capacityd') as HTMLInputElement).value,
       limit_days: (document.getElementById('limit_daysd') as HTMLInputElement).value,
       currency: (document.getElementById('currencyd') as HTMLInputElement).value,
       expiration_day: (document.getElementById('expiration_dayd') as HTMLInputElement).value,
       expiration_type: (document.getElementById('expiration_typed') as HTMLInputElement).value,
       status: (document.getElementById('statusd') as HTMLInputElement).value,
       with_recourse: (document.querySelector('input[name = "with_recoursed"]:checked') as HTMLInputElement).value,
       token: '',
       secret_key: ''};
    this._contribuyentesService.guardaSegmento( this.idcadena, data ).subscribe( resp => {
      Swal.fire({
        title: 'Los datos fueron guardados',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
         // window.location.reload();
         this.actualizaBorraoGuarda(this.idl);
        }

      } );
    }, (err) => {
      console.log(err);
      Swal.fire(
           'Error al guardar los datos',
           '',
           'error'
        );
     } );
  }

  editaSegmentoCadenaPesos(idsegmento) {
    const d = new Date((document.getElementById('start_datepe')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    const startdaten = [year, month, day].join('-');

    const e = new Date((document.getElementById('end_datepe')as HTMLInputElement).value);
    e.setMinutes( e.getMinutes() + e.getTimezoneOffset() );
    let monthe = '' + (e.getMonth() + 1);
    let daye = '' + e.getDate();
    const yeare = e.getFullYear();

    if (monthe.length < 2) {
        monthe = '0' + monthe;
    }
    if (daye.length < 2) {
        daye = '0' + daye;
    }

    const enddaten = [yeare, monthe, daye].join('-');

       const data = {company_id: this.idcadena, id: '',
       key: (document.getElementById('keype') as HTMLInputElement).value,
       name: (document.getElementById('namepe') as HTMLInputElement).value,
       start_date: startdaten,
       end_date: enddaten,
       rate: (document.getElementById('ratepe') as HTMLInputElement).value,
       fee: (document.getElementById('feepe') as HTMLInputElement).value,
       capacity: (document.getElementById('capacitype') as HTMLInputElement).value,
       limit_days: (document.getElementById('limit_dayspe') as HTMLInputElement).value,
       currency: (document.getElementById('currencype') as HTMLInputElement).value,
       expiration_day: (document.getElementById('expiration_daype') as HTMLInputElement).value,
       expiration_type: (document.getElementById('expiration_typepe') as HTMLInputElement).value,
       status: (document.getElementById('statuspe') as HTMLInputElement).value,
       with_recourse: (document.querySelector('input[name = "with_recoursepe"]:checked') as HTMLInputElement).value,
       token: '',
       secret_key: ''};
    this._contribuyentesService.actualizaSegmento( this.idcadena, idsegmento, data ).subscribe( resp => {
      Swal.fire({
        title: 'Los datos fueron actualizados',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
         // window.location.reload();
         this.actualizaBorraoGuarda(this.idl);
        }

      } );
    }, (err) => {
      console.log(err);
      Swal.fire(
           'Error al guardar los datos',
           '',
           'error'
        );
     } );
  }

  editaSegmentoCadenaDolares(idsegmento) {
    const d = new Date((document.getElementById('start_datede')as HTMLInputElement).value);
    d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    const startdaten = [year, month, day].join('-');

    const e = new Date((document.getElementById('end_datede')as HTMLInputElement).value);
    e.setMinutes( e.getMinutes() + e.getTimezoneOffset() );
    let monthe = '' + (e.getMonth() + 1);
    let daye = '' + e.getDate();
    const yeare = e.getFullYear();

    if (monthe.length < 2) {
        monthe = '0' + monthe;
    }
    if (daye.length < 2) {
        daye = '0' + daye;
    }

    const enddaten = [yeare, monthe, daye].join('-');

       const data = {company_id: this.idcadena, id: '',
       key: (document.getElementById('keyde') as HTMLInputElement).value,
       name: (document.getElementById('namede') as HTMLInputElement).value,
       start_date: startdaten,
       end_date: enddaten,
       rate: (document.getElementById('ratede') as HTMLInputElement).value,
       fee: (document.getElementById('feede') as HTMLInputElement).value,
       capacity: (document.getElementById('capacityde') as HTMLInputElement).value,
       limit_days: (document.getElementById('limit_daysde') as HTMLInputElement).value,
       currency: (document.getElementById('currencyde') as HTMLInputElement).value,
       expiration_day: (document.getElementById('expiration_dayde') as HTMLInputElement).value,
       expiration_type: (document.getElementById('expiration_typede') as HTMLInputElement).value,
       status: (document.getElementById('statusde') as HTMLInputElement).value,
       with_recourse: (document.querySelector('input[name = "with_recoursede"]:checked') as HTMLInputElement).value,
       token: '',
       secret_key: ''};
    this._contribuyentesService.actualizaSegmento( this.idcadena, idsegmento, data ).subscribe( resp => {
      Swal.fire({
        title: 'Los datos fueron actualizados',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {

        if ( res.value ) {
         // window.location.reload();
         this.actualizaBorraoGuarda(this.idl);
        }

      } );
    }, (err) => {
      console.log(err);
      Swal.fire(
           'Error al guardar los datos',
           '',
           'error'
        );
     } );
  }

  borraSegmento(seg) {
    Swal.fire({
      title: 'Desea Eliminar el segmento',
      text: '',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {
    this._contribuyentesService.borraSegmento(seg.company_id, seg.id).subscribe(resp => {
  
      Swal.fire({
        title: 'Los datos fueron borrados',
        text: 'Con exito',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
  
        if ( res.value ) {
         // window.location.reload();
         this.actualizaBorraoGuarda(this.idl);
        }
  
      } );
    }, (err) => {
      console.log(err);
      Swal.fire(
           'Error al borrar los datos',
           err.error.errors[0],
           'error'
        );
     } );
  
  }
  });
  }
}
