import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ContribuyentesService, OptionsService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-mantcontmain',
  templateUrl: './mantcontmain.component.html',
  styleUrls: ['./mantcontmain.component.css']
})
export class MantContMainComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  contribuyenteEmitter = new EventEmitter<Object>(); //declaro el emisor
  escriturasEmitter = new EventEmitter<Object>(); //declaro el emisor
  firmantesEmitter = new EventEmitter<Object>(); //declaro el emisor
  domiciliosEmitter = new EventEmitter<Object>(); //declaro el emisor
  actacEmitter = new EventEmitter<Object>(); //declaro el emisor
  afiliacionEmitter = new EventEmitter<Object>(); //declaro el emisor
  idc;
  clk = false;
  nombcont = '';
  constructor( public route: ActivatedRoute
                ) {swal2.fire('espere, por favor');
                swal2.showLoading(); }



  ngOnInit() {
    this.subscribeToParentEmitter();
   /* this.idc = this.route.snapshot.paramMap.get('id');
    setTimeout(() => {
      
      this.handleClickNumber(0);

    }, 1000); */
  }

  subscribeToParentEmitter(): void { 
    
    this.eventEmitter.subscribe(d => {
      console.log(d)
      swal2.showLoading();
      this.clk = false;
    this.idc = d.contid;
    this.nombcont = d.nomcont;
    setTimeout(() => {
      const checkele = document.getElementById("one") as HTMLInputElement;
      checkele.checked = true;
      this.handleClickNumber(0);

    }, 1000);
    })
  }

  handleClickNumber(tab: number) {
    switch (tab) {
      case 0:
        this.contribuyenteEmitter.emit({idc: this.idc});
        break
      case 1:
        this.escriturasEmitter.emit({idc: this.idc});
        break;
      case 2:
        this.firmantesEmitter.emit({idc: this.idc});
        break;
      case 3:
        this.domiciliosEmitter.emit({idc: this.idc});
        break;
      case 4:
        this.actacEmitter.emit({idc: this.idc});
        break;
      case 5:
        this.afiliacionEmitter.emit({idc: this.idc});
        break;
      default:
        //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
  }

  cch() {
    this.clk = !this.clk;
    this.childEvent.emit({clk: this.clk})
  }

}
